<template>
  <main
    class="px-4 mb-4 mt-[60px] md:mt-[88px] pt-4 max-lg:max-w-[768px] lg:max-w-[1280px] mx-auto"
  >
    <SiteBlogHeader class="bg-white rounded shadow-lg" hide-search />
    <SiteBreadcrumbs :items="breadcrumbs" />
    <div class="pt-4 grid grid-cols-1 lg:grid-cols-[2fr_1fr] gap-8">
      <SiteBlogPost :post="post" />
      <div class="flex flex-col items-center gap-5">
        <div
          ref="contactForm"
          class="w-full border border-line-light rounded-lg shadow-xl-right px-3 py-4 text-center md:sticky md:top-[104px]"
        >
          <div id="contact-form" class="-mt-[116px] pt-[116px]"></div>
          <h5 class="text-lg font-bold mb-2">
            {{ $t('contactForm.title') }}
          </h5>
          <p class="text-[14px] mb-2 [text-wrap:balance]">
            {{ $t('contactForm.description') }}
          </p>
          <HubspotForm
            element-id="blog-post-form"
            :form-id="{
              en: '47c77108-03dd-47b5-8a71-b68d32f4cfaa',
              es: '6c494fbe-f186-4a01-aab8-b08130dd1a44'
            }"
          />
        </div>
      </div>
    </div>
    <h5 v-if="post?.relatedPosts?.length" class="text-lg font-bold mt-8 -mb-4">
      {{ $t('blog.relatedPosts') }}
    </h5>
    <SiteBlogResults
      v-if="post?.relatedPosts?.length"
      :items="post.relatedPosts"
      :total-pages="0"
    />
  </main>
</template>

<script setup lang="ts">
import type { Post } from '@/types/common';

const HubspotForm = defineAsyncComponent(
  () => import('@/async-components/HubspotForm.vue')
);
const SiteBlogHeader = defineAsyncComponent(
  () => import('@/async-components/Site/Blog/Header.vue')
);
const SiteBlogPost = defineAsyncComponent(
  () => import('@/async-components/Site/Blog/Post.vue')
);
const SiteBlogResults = defineAsyncComponent(
  () => import('@/async-components/Site/Blog/Results.vue')
);
const SiteBreadcrumbs = defineAsyncComponent(
  () => import('@/async-components/Site/Breadcrumbs.vue')
);

const props = defineProps<{
  post: Post;
  breadcrumbs: { name: string; item: string; interactuable?: boolean }[];
  fixLocale: string;
}>();

const { locale, setLocale } = useI18n();

if (props.fixLocale && locale.value !== props.fixLocale) {
  setLocale(props.fixLocale);
}
</script>
