<template>
  <main
    class="px-4 mb-4 mt-[60px] md:mt-[88px] pt-4 max-lg:max-w-[768px] lg:max-w-[1280px] mx-auto"
  >
    <SiteBreadcrumbs :items="breadcrumbs" />
    <h1 class="text-center text-xl md:text-2xl lg:text-[2.5rem] mb-4">
      {{ $t('blog.title') }}
    </h1>
    <div class="title-divider mx-auto mb-4"></div>
    <div class="text-center mb-6">
      {{ $t('blog.subtitle') }}
    </div>
    <SiteBlogHeader
      class="bg-white sticky rounded shadow-lg top-[80px] md:top-[104px]"
    />
    <SiteBlogResults
      :page="page"
      :items="['loading', 'initializing'].includes(requestState) ? null : items"
      :total-pages="
        ['loading', 'initializing'].includes(requestState)
          ? 0
          : Math.ceil((found || 0) / POSTS_PER_PAGE)
      "
      :base-url="localePath(`/${$t('routes.blog')}`)"
    />
  </main>
</template>

<script setup lang="ts">
import type { PostCard } from '@/types/common';

const SiteBlogHeader = defineAsyncComponent(
  () => import('@/async-components/Site/Blog/Header.vue')
);
const SiteBlogResults = defineAsyncComponent(
  () => import('@/async-components/Site/Blog/Results.vue')
);
const SiteBreadcrumbs = defineAsyncComponent(
  () => import('@/async-components/Site/Breadcrumbs.vue')
);

const POSTS_PER_PAGE = 12;

defineProps<{
  requestState?: 'initializing' | 'loading' | 'error' | 'success';
  found: number;
  items: PostCard[];
  page: number;
  breadcrumbs: { name: string; item: string; interactuable?: boolean }[];
}>();

const localePath = useLocalePath();
</script>
